#config {
  .instance-details {
    .card-body {
      padding-bottom: 0;
    }

    .nowrap-scroll {
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
    }
  }

  .item-list {
    font-size: 0.65rem;

    .item-scroller {
      height: calc(100vh - 500px);
      overflow-x: hidden;
      overflow-y: auto;

      @media only screen and (max-width: 991px) {
        height: auto;
      }

      .item-row {
        border-bottom: 1px solid $lightest-grey-overlay;
        padding: 8px 0;

        .text-nowrap {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .error,
        .fatal {
          color: $color-danger;
        }

        .warn {
          color: $color-warning;
        }

        .complete,
        .notify {
          color: $color-success;
        }
      }
    }
  }

  .configLoader {
    height: 38px;
    padding-top: 15px;
    text-align: center;
    width: 100%;
  }

}
