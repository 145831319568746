.dark {
  #app-nav {
    background: $color-pureblack !important;
    border-bottom: 1px solid $color-darkestgrey;

    .navbar-nav {
      .active-org {
        background: $faintest-white-overlay !important;
      }
    }
  }

  .app-subnav {
    border-bottom: 1px solid $color-darkestgrey;

    .filter-holder {
      input {
        background: none !important;
        border-color: $faint-white-overlay !important;
      }
    }
  }

  #app-bg-color {
    background: $color-pureblack !important;
    z-index: -2;
  }

  a {
    color: $color-lightergrey !important;

    &:hover {
      color: $color-white !important;
    }
  }

  .card {
    background: $color-pureblack !important;
    border: 1px solid $color-darkestgrey !important;
    box-shadow: 0 0 5px $color-pureblack !important;
    color: $color-lightgrey !important;

    .card-body {
      background: $gradient-white-lightgrey !important;
    }
  }

  hr,
  .item-row,
  .log-row,
  .radio-button > div,
  .modal-header,
  .card.error,
  .card.success,
  .past-query {
    border-color: $faint-white-overlay !important;
  }

  .past-query {
    background-color: $faintest-white-overlay !important;
    color: $color-white;
  }

  .lolight {
    background-color: $color-pureblack !important;
    border: 1px solid $faint-white-overlay !important;
  }

  input,
  select,
  textarea,
  .fake-input,
  .form-control {
    background: $faintest-white-overlay !important;
    border: 1px solid $faint-white-overlay !important;
    color: $color-lightgrey !important;
  }

  .entity-manager {
    .card {
      background-color: $color-pureblack !important;
      border: 1px solid $color-black;

      .item-row {
        background-color: transparent !important;

        &.active {
          background-color: transparent !important;
        }
      }
    }
  }

  .content-container {
    .header-container {
      border-bottom: 1px solid $faint-white-overlay;
    }
  }

  .react-table-scroller {
    .header {
      .col {
        border-top: 1px solid $faint-white-overlay;

        &.sorted {
          &.desc {
            box-shadow: inset 0 -2px 0 0 $faint-white-overlay;
          }

          &.asc {
            box-shadow: inset 0 2px 0 0 $faint-white-overlay;
          }

        }
      }
    }

    .rows {
      border-bottom: 1px solid $faint-white-overlay;
    }

    .row {
      .col {
        border-bottom: 1px solid $faint-white-overlay;
      }
    }

    input,
    select {
      color: $color-white;
    }

    .image-renderer {
      .preview-image {
        &.no-image {
          background: $color-white;
        }
      }
    }
  }

  .react-select-container {
    width: 100%;

    .react-select__control,
    .react-select__control:hover,
    .react-select__control:focus,
    .react-select__control--menu-is-open,
    .react-select__control--is-focused,
    .react-select__indicator-separator {
      background: $faintest-white-overlay !important;
      border-color: $faint-white-overlay !important;

      .react-select__single-value {
        color: $color-lightgrey;
      }

      .react-select__indicator-separator {
        background: $faint-white-overlay !important;
      }
    }

    .react-select__option,
    .react-select__option:hover,
    .react-select__option:focus,
    .react-select__option--is-focused {
      background: $faintest-white-overlay !important;
      border-bottom: 1px solid $faint-white-overlay;
      color: $color-lightgrey !important;

      &:last-child {
        border-bottom: 0;
      }
    }

    .react-select__menu {
      background: $color-pureblack !important;
    }
  }

  .react-toggle {
    .react-toggle-track {
      background: transparent !important;
      border: 1px solid $faint-white-overlay !important;
    }

    &--checked {
      .react-toggle-track {
        background: $color-success !important;
      }
    }
  }

  .drop-zone {
    background: radial-gradient($faint-white-overlay 1px, transparent 0) !important;
    background-size: 3px 3px !important;
    color: $color-lightgrey !important;
  }

  #replaceFile {
    color: $color-white;
  }

  .modal-content {
    background: $color-pureblack !important;
    color: $color-lightgrey !important;

    .modal-header {
      background: $dark-grey-overlay !important;
    }
  }

  #login-form {
    .card {
      background: $color-pureblack !important;
    }

    input {
      background: $faintest-white-overlay !important;
    }
  }

  .apexcharts-tooltip {
    background: $color-pureblack !important;
    color: $color-white !important;

    .apexcharts-tooltip-title {
      background: $color-pureblack !important;
    }
  }

  ::-webkit-scrollbar-track {
    background: $color-pureblack;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $faint-white-overlay;
    border: 2px solid $color-pureblack;
    border-radius: $border-radius;
  }

  a,
  button,
  input,
  textarea,
  .item-row,
  select,
  i,
  .card,
  .btn,
  [role='button'] {
    &:focus {
      outline-color: $light-white-overlay !important;
    }
  }

  .btn-pagination {
    background-color: $color-black !important;
    border: 1px solid $faint-white-overlay !important;
    color: $color-white !important;
  }


}
