#webide{
  height: 80vh;
  overflow: hidden;

  .editor-menu-holder,
  .file-menu-holder {
    border-bottom: 1px solid $color-darkgrey !important;
    color: $color-white;
    height: 30px;
    margin-bottom: 20px;
  }

  .file-menu,
  .editor-menu {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    margin-top: 0;
    padding-bottom: 0;
    padding-inline-start: 0 !important;
    padding-top: 0;

    .file-menu-item,
    .editor-menu-item {
      list-style-type: none;
      margin: 0 4px 8px !important;

      button {
        color: $color-white !important;
        background: transparent !important;
        border: 0;
        margin: 0;
        outline: none !important;
        padding: 0;
      }

      button:disabled,
      button[disabled],
      button:disabled::before,
      button[disabled]::before{
        color: $color-darkgrey !important;
        cursor: not-allowed;
      }
    }
  }

  .editor-menu,
  .file-menu-right {
    justify-content: right !important;
  }

  .file-browser-outer-container {
    height: 100%;
    padding: 20px;

    @media(max-width: 814px) {
      height: auto;
      overflow-y: auto;
    }

    .file-browser-scroll-container {
      background: $faintest-white-overlay;
      border: 1px solid $color-darkgrey;
      border-radius: $border-radius;
      height: 72vh !important;
      overflow-x: hidden;
      overflow-y: auto;

      @media(max-width: 814px) {
        max-height: 500px;
      }

      .file-browser {
        margin-left: 0;
        padding: 0 !important;
        position: relative;

        > li {
          border-bottom: 1px solid $color-darkgrey;
          padding: 10px 5px;
          width: 100%;

          &:last-child {
            border-bottom: 0 !important;
          }
        }

        .folder {
          list-style-type: none;
          padding: 0 !important;
          padding-inline-start: 8px !important;

          .folder-container {
            button::before,
            i::before {
              background: transparent !important;
              border: 0;
              font-size: 16px !important;
              margin: 0;
              outline: none !important;
              padding: 0;
            }

            .package-text,
            .filename-text {
              color: $color-grey !important;
              font-size: 14px !important;
              padding-left: 8px;
            }

            .file,
            .package {
              background: transparent;
              border: 0;
              margin: 1px 0;
              outline: none !important; // TODO: fix this because it is not accessible; style differently
              padding: 3px;
              text-align: left;
              white-space: nowrap;

              .project-icon {
                color: $color-success;
              }

              .folder-icon {
                color: $color-info;
              }

              .filetype-js {
                color: $color-warning;
              }

              .filetype-yaml {
                color: $color-primary;
              }

              .filetype-unknown {
                color: $color-lightgrey;
              }

              &.folder-selected {
                .filename-text {
                  color: $color-white !important;
                }
              }

              &.file-selected {
                .filename-text {
                  color: $color-white !important;

                  &::after {
                    content: '\f35a' !important;
                    font-family: 'Font Awesome 5 Free', sans-serif !important;
                    font-size: 16px;
                    position: absolute;
                    right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .editor-window-container {
    padding: 20px;

    .editor-window {
      background: $faintest-white-overlay;
      border: 1px solid $color-darkgrey;
      border-radius: $border-radius;
      height: 72vh !important;
      padding: 0;

      > section,
      > section > div,
      .monaco-editor,
      .overflow-guard,
      .card {
        height: 71.8vh !important;
      }
    }
  }

  .content-window {
    align-items: center;
    color: $color-white;
    display: flex;
    flex-direction: column;
    height: 72vh;
    justify-content: center;
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
  }
}
