#app-nav {
  background: $gradient-purple-magenta !important;
  border-bottom: 1px solid $black-overlay;
  height: 60px;
  justify-content: space-between !important;
  left: 0;
  min-width: $min-width;
  overflow-x: auto;
  padding: 0 2rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99 !important;

  @media screen and (max-width: 991px) {
    padding: 0 1rem;
  }

  .container-fluid {
    padding: 0;
  }

  .navbar-brand {
    margin-right: 0;
    padding: 0;
    position: relative;

    #logo {
      background-image: url('../../images/logo/logo_dog.png');
      background-position: center left;
      background-repeat: no-repeat;
      background-size: contain;
      height: 30px;
      width: 120px;

      @media screen and (max-width: 530px) {
        background-image: url('../../images/logo/logo_dog_only.png');
        width: 30px;
      }
    }
  }

  .navbar-nav {
    justify-content: flex-end;

    .nav-item {
      align-items: center;
      display: inline-flex;
      margin-bottom: 0 !important;
      margin-left: 1rem;
      white-space: nowrap;

      @media screen and (max-width: 511px) {
        margin-left: 0.5rem;
      }

      .btn-link,
      .nav-link,
      a {
        color: $white-overlay !important;
        display: inline-block;
        min-width: 15px;
        padding: 0 !important;
        position: relative;
        transition: color 250ms;

        &.active,
        &:hover {
          color: $color-white !important;
        }
      }
    }

    .login-text-label {
      min-width: 52px;
    }

    .active-org {
      align-items: center;
      background: $faint-white-overlay;
      border-right: 1px solid $faint-white-overlay;
      display: inline-flex;
      flex: 0;
      height: 34px;
      justify-content: flex-end;
      margin-left: 1rem;
      overflow: hidden;
      transition: flex 500ms;

      @media screen and (max-width: 511px) {
        margin-left: 0.5rem;
      }

      &.open {
        border: 1px solid $faint-white-overlay;
        border-radius: $border-radius;
        flex: 1;
      }

      .org-name,
      .org-actions {
        align-items: center;
        display: inline-flex;
        height: 100%;
        justify-content: center;
        padding: 0 15px;

        @media screen and (max-width: 511px) {
          padding: 0 5px;
        }
      }

      .org-name {
        border-right: 1px solid $faint-white-overlay;
        white-space: nowrap;

        @media screen and (max-width: 511px) {
          font-size: 10px;
          font-weight: $font-weight-bold;
          max-width: 75px;
          white-space: normal;
        }
      }
    }
  }
}
