$split-height: calc(50vh - 182px) !important;

#query {
  #query-history {
    .query-scroller {
      height: $split-height;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;

      @media screen and (max-width: 767px) {
        height: 120px !important;
        max-height: 120px;
        min-height: 120px;
      }
    }

    .past-query {
      background: $gradient-white-lightgrey;
      border: 0;
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
      font-size: $font-size-small;
      margin-bottom: 1px;
      margin-top: 1px;
      outline: 0 !important;
      overflow-x: auto;
      padding: 1rem 0;
      text-align: left;
      white-space: nowrap;
      width: 100%;

      &:first-child {
        border-top: 0 !important;
      }

      span {
        padding: 0 1rem;
      }
    }
  }

  #query-window {
    textarea {
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
      height: $split-height;
      tab-size: 2;

      @media screen and (max-width: 767px) {
        height: 100px !important;
        max-height: 100px;
        min-height: 100px;
      }
    }
  }
}


