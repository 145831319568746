body {
  color: $color-black;
  font-family: $font-family-sans;
  font-size: $font-size-root;
  letter-spacing: 0.02rem;
  line-height: $font-line-height;
}

h1 {
  font-size: $font-size-h1;
  font-weight: $font-weight-light;
  margin: 0 !important;
  @media (min-width: 1100px) {
    font-size: $font-size-h1-max;
  }
}

h2 {
  font-size: $font-size-h2;
  font-weight: $font-weight-light;
  margin: 0 !important;
  @media (min-width: 1100px) {
    font-size: $font-size-h2-max;
  }
}

h3 {
  font-size: $font-size-h3;
  font-weight: $font-weight-light;
  margin: 0 !important;
  @media (min-width: 1100px) {
    font-size: $font-size-h3-max;
  }
}

h4 {
  font-size: $font-size-h4;
  font-weight: $font-weight-light;
  margin: 0 !important;
  @media (min-width: 1100px) {
    font-size: $font-size-h4-max;
  }
}

h5 {
  font-size: $font-size-h5;
  font-weight: $font-weight-light;
  margin: 0 !important;
  @media (min-width: 1100px) {
    font-size: $font-size-h5-max;
  }
}

h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-light;
  margin: 0 !important;
  @media (min-width: 1100px) {
    font-size: $font-size-h6-max;
  }
}

.logo-header {
  align-content: center;
  display: flex;
  flex-direction: row;
  font-size: 22px !important;
  font-weight: bold;
  height: 38px;
  line-height: 25px;
  padding-top: 6px;
}

b,
.text-bold {
  font-weight: $font-weight-bold;
}

.text-small {
  font-size: $font-size-small;
  line-height: 1 !important;
}

.text-white {
  color: $color-white !important;
}

.text-lightgrey {
  color: $color-lightgrey !important;
}

.text-lightpurple {
  color: $color-lightpurple !important;
}

.text-grey {
  color: $color-grey !important;
}

.text-success {
  color: $color-success !important;
}

.text-danger {
  color: $color-danger !important;
}

.text-warning {
  color: $color-warning !important;
}

.text-darkgrey {
  color: $color-darkgrey !important;
}

.text-white-overlay {
  color: rgba(255, 255, 255, 0.6);
}

.text-purple {
  color: $color-purple !important;
}
