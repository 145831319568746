#csv-upload {
  .csv-status,
  .drop-zone {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: $font-size-small;
    height: 38px;
    justify-content: center;
    overflow: hidden;
  }

  .drop-zone {
    background: radial-gradient($color-lightgrey 1px, transparent 0);
    background-size: 3px 3px !important;
    border: 1px solid $lighter-grey-overlay;
    border-radius: $border-radius;
    box-shadow: inset 0 0 5px $lighter-grey-overlay !important;
    color: $color-black;
    font-size: $font-size-small;
    outline: none !important;
    text-align: center;
  }

  .btn {
    height: 38px;
  }
}
