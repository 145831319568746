.react-table-holder {
  .react-table-scroller {
    border-bottom: 1px solid $lighter-grey-overlay;
    font-size: 12px;
    min-height: calc(100vh - 328px);
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;

    @media screen and (max-width: 767px) {
      min-height: 150px;
    }

    .header {
      font-weight: $font-weight-bold;

      .col {
        border-top: 1px solid $lighter-grey-overlay;
        min-width: 100px;
        white-space: nowrap;

        &.sorted {
          &.desc {
            box-shadow: inset 0 -2px 0 0 $lighter-grey-overlay;
          }

          &.asc {
            box-shadow: inset 0 2px 0 0 $lighter-grey-overlay;
          }

        }
      }
    }

    .filter {
      .col {
        padding: 0.4rem 0.3rem 0.4rem 0 !important;

        input,
        select {
          background: $light-grey-overlay;
          box-shadow: none;
          color: $color-black;
          height: 24px !important;
          width: 100%;
        }
      }
    }

    .centered {
      left: 0;
      position: absolute;
      right: 0;
      top: 40%;
    }

    .row {
      cursor: pointer;
      flex-wrap: nowrap;
      margin: 0;
      overflow: visible;
      white-space: nowrap;
      width: 100%;

      .col {
        align-items: center;
        border-bottom: 1px solid $lighter-grey-overlay;
        display: flex;
        height: 30px;
        margin-bottom: 0 !important;
        min-width: 100px;
        padding: 0 0.25rem;
        white-space: nowrap;

        &.action {
          justify-content: center;
          max-width: 75px;
          min-width: 75px;
        }
      }
    }

    .text-renderer {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .image-renderer {
      padding-left: 10px;
      position: relative;

      .preview-image {
        border-radius: $border-radius;
        box-shadow: 0 0 4px $grey-overlay;
        left: 40px;
        max-height: 600px;
        max-width: 600px;
        position: absolute;
        top: -5px;
        width: auto;
        z-index: 99;

        &.no-image {
          align-items: center;
          background: $color-white;
          display: flex;
          flex-direction: column;
          height: 60px;
          justify-content: center;
          width: 150px;
        }
      }
    }
  }

  .pagination {
    padding-top: 20px;

    .btn {
      max-width: 100px;

      @media screen and (max-width: 575px) {
        max-width: 100%;
      }
    }

    .next {
      .btn {
        float: right;
      }
    }


    .paginator {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      input {
        margin: 0 5px;
        padding-left: 3px;
        padding-right: 3px;
        text-align: center;
        vertical-align: middle;
        width: 100%;
      }

      .page-count {
        display: inline-block;
        height: 100%;
        padding-top: 11px;
        white-space: nowrap;
      }
    }

    .page-size {
      select {
        width: 100%;
      }
    }
  }

  &.loading {
    .action * {
      cursor: not-allowed;
      opacity: 0.75;
    }
  }
}
