.entity-manager {
  padding-bottom: 10px;

  .card {
    overflow: hidden;

    .card-body {
      padding: 0 0.75rem;

      &.scrollable {
        max-height: calc(50vh - 160px);
        min-height: 49px;
        overflow-x: hidden;
        overflow-y: auto;

        &.creating {
          max-height: calc(50vh - 209px);
        }
      }

      .item-row {
        border-top: 1px solid $lighter-grey-overlay;
        cursor: pointer;
        height: 49px;
        overflow: hidden;

        &.active {
          font-weight: $font-weight-bold;
        }

        &:first-child {
          border-top: 0;
        }

        .input-holder {
          flex-grow: 1;
          flex-shrink: 0;
          padding: 0 6px;
          width: 100%;

          input {
            background: rgba(255, 255, 255, 0.3);
            border-color: transparent;
            color: $color-black;

            &.is-invalid {
              border-color: $color-danger;
            }

            &.table {
              width: 50%;
            }
          }
        }

        .item-label,
        .item-action,
        .input-holder {
          align-items: center;
          display: flex;
        }

        .item-label {
          justify-content: flex-start;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .item-action {
          flex-grow: 0;
          flex-shrink: 1;
          justify-content: flex-end;
          min-width: 80px;
          padding-left: 0;

          .btn {
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .fa-sm {
    font-size: 0.8rem;
  }

  &-footer {
    color: $color-white;
  }
}
