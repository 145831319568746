#app-bg-color {
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
}

#app-bg-color {
  background: $gradient-purple-magenta !important;
  z-index: -2;
}
