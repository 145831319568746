#clustering {
  .notification-holder {
    min-height: 120px;

    .cluster-notifications {
      color: $color-danger;
      height: 100%;
    }
  }
}
