#login-form {
  left: 50%;
  max-width: 270px;
  min-width: 270px;
  position: relative;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;

  .card {
    background: $faint-white-overlay !important;
    border: 1px solid $faint-white-overlay !important;
    box-shadow: none !important;
    z-index: 2;

    .card-body {
      color: $color-white;
      min-height: 205px;

      .instructions {
        color: $color-white;
        padding-bottom: 16.5px;
        text-align: center;
      }
    }
  }

  &.sign-up {
    max-width: 770px;

    .card-body {
      padding: 2rem !important;
    }

    @media screen and (max-width: 767px), screen and (max-height: 530px) {
      margin-bottom: 50px;
      top: -50px;
      transform: translateX(-50%);
    }

    hr {
      background: $faint-white-overlay;
    }

    .sign-up-header {
      border-bottom: 1px solid $faint-white-overlay;
      font-size: 1.5rem !important;
      line-height: 1 !important;
      margin-bottom: 25px !important;
      padding-bottom: 25px;
      text-align: center;
      width: 100%;

      @media screen and (max-width: 767px) {
        font-size: 1.1rem !important;
      }
    }

    .sign-up-content {
      width: 100%;

      h3 {
        font-size: 1.1rem !important;
        padding-top: 15px;
      }
    }

    .sign-up-form {
      .subdomain-explanation {
        display: block;
        font-size: 0.7rem;
        padding-bottom: 8px;
      }
    }
  }

  input {
    background: $faint-white-overlay !important;
    color: $color-white;
    text-align: center !important;

    &::placeholder {
      color: $white-overlay !important;
      font-family: $font-family-sans;
      font-weight: $font-weight-light;
      text-align: center !important;
    }
  }

  .disclaimer {
    color: $white-overlay !important;
    font-size: $font-size-small;

    a,
    a:hover {
      color: $light-white-overlay !important;
      text-decoration: underline !important;
    }
  }

  .btn {
    text-transform: lowercase;
  }

  .login-nav-link-holder {
    &:last-child {
      text-align: right;
    }
  }

  .login-nav-link {
    color: $white-overlay !important;
    display: inline-block;
    font-size: 85%;
    height: 16px;
    text-transform: lowercase;
    white-space: nowrap;

    &:hover {
      color: $color-white !important;
    }

    &.error {
      color: $color-danger !important;
      font-weight: $font-weight-bold;
      text-align: center;
      white-space: normal;
      width: 100%;
    }
  }
}
