// color palette
$color-pureblack: #111;
$color-black: #212121;
$color-darkestgrey: #232323;
$color-darkergrey: #555;
$color-darkgrey: #696969;
$color-grey: #9a9a9a;
$color-lightgrey: #ddd;
$color-lightergrey: #ededed;
$color-lightestgrey: #efefef;
$color-white: #fefefe;

$color-primary: #c4c4ff;
$color-success: #55c58f;
$color-warning: #ffa500;
$color-danger: #ea4c89;
$color-info: #8F8DFF;
$color-darkpurple: #312556;
$color-purple: #403b8a;
$color-lightpurple: #7a3a87;

$gradient-purple-magenta: linear-gradient(45deg, $color-darkpurple, $color-purple, $color-lightpurple);
$gradient-white-lightgrey: linear-gradient(to bottom, rgba(255, 255, 255, 0.01) 31%, rgba(0, 0, 0, 0.05) 100%);
$faintest-white-overlay: rgba(255, 255, 255, 0.01);
$fainter-white-overlay: rgba(255, 255, 255, 0.05);
$faint-white-overlay: rgba(255, 255, 255, 0.1);
$white-overlay: rgba(255, 255, 255, 0.3);
$whiter-overlay: rgba(255, 255, 255, 0.5);
$light-white-overlay: rgba(255, 255, 255, 0.9);

$lightest-grey-overlay: rgba(0, 0, 0, 0.03);
$lighter-grey-overlay: rgba(0, 0, 0, 0.1);
$light-grey-overlay: rgba(0, 0, 0, 0.3);
$grey-overlay: rgba(0, 0, 0, 0.5);
$dark-grey-overlay: rgba(0, 0, 0, 0.7);
$black-overlay: rgba(0, 0, 0, 0.9);

$border-radius: 4px;
$border-radius-lg: 6px;
$border-radius-sm: 2px;

$font-family-sans: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans", Arial, Helvetica, Verdana, Sans-Serif !important;

$font-size-root: 13px !important;
$font-size-h1: calc(3vw + 3vh) !important;
$font-size-h1-max: 65px !important;
$font-size-h2: calc(2.5vw + 2.5vh) !important;
$font-size-h2-max: 54px !important;
$font-size-h3: calc(2vw + 2vh) !important;
$font-size-h3-max: 43px !important;
$font-size-h4: calc(1.5vw + 1.5vh) !important;
$font-size-h4-max: 32px !important;
$font-size-h5: 1.5rem !important;
$font-size-h5-max: 1.5rem !important;
$font-size-h6: 1.25rem !important;
$font-size-h6-max: 1.25rem !important;

$font-size-small: 85% !important;

$font-weight-light: 300 !important;
$font-weight-bold: 600 !important;
$font-line-height: 1.2 !important;

$min-width: 314px;
