$partner-brand-color: #0075c9;

.lumen {
  #app-nav {
    background: $color-white !important;
    border-bottom: 1px solid $color-lightgrey;

    #logo {
      background-image: url('../../images/logo/lumen.png');
      @media screen and (max-width: 530px) {
        background-image: url('../../images/logo/lumen_e_only.png');
        width: 17px;
      }
    }

    .navbar-nav {
      .active-org {
        background: $gradient-white-lightgrey;
        border-right-color: $color-grey !important;

        .org-name {
          color: $color-black !important;
        }
      }

      .nav-item {
        .btn-link,
        .nav-link,
        a {
          color: $grey-overlay !important;

          &.active {
            color: $black-overlay !important;
          }
        }
      }
    }
  }

  #app-bg-color {
    background: $color-white !important;
    z-index: -2;
  }

  .app-subnav {
    background: $partner-brand-color !important;
    border-bottom: 1px solid $color-lightgrey;

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $color-white !important;

          &.active {
            color: $color-white !important;
          }
        }
      }
    }

    .react-select-container {
      .react-select__control,
      .react-select__control:hover,
      .react-select__control:focus,
      .react-select__control--menu-is-open,
      .react-select__control--is-focused,
      .react-select__indicator-separator {
        background: transparent !important;
        border-color: $color-white !important;

        .react-select__single-value {
          color: $color-white;
        }

        .react-select__indicator-separator {
          background: $lighter-grey-overlay !important;
        }
      }

      .react-select__option,
      .react-select__option:hover,
      .react-select__option:focus,
      .react-select__option--is-focused {
        background: $color-lightergrey !important;
        color: $partner-brand-color !important;
      }

      .react-select__menu {
        background: $color-lightergrey !important;
      }
    }

    .filter-holder {
      input {
        border: 1px solid $lighter-grey-overlay !important;
        color: $color-white !important;
      }

      .clear-filter {
        color: $color-white;
      }

      ::placeholder {
        color: $whiter-overlay !important;
      }
    }

    .refresh-instances {
      color: $color-white;
    }
  }

  a {
    color: $partner-brand-color !important;

    &:hover {
      color: $partner-brand-color !important;
    }
  }

  hr,
  .item-row,
  .log-row,
  .radio-button > div,
  .modal-header,
  .card.error,
  .card.success,
  .past-query {
    border-color: $lighter-grey-overlay !important;
  }

  .lolight {
    background-color: $dark-grey-overlay !important;
    border: 1px solid $lighter-grey-overlay !important;
  }

  .loader {
    .card {
      background: $color-white !important;
      border: 1px solid $lighter-grey-overlay !important;
      box-shadow: 0 0 5px $lighter-grey-overlay !important;

      .card-body {
        background: $gradient-white-lightgrey !important;
        color: $color-darkgrey !important;
      }
    }
  }

  .floating-card-header {
    color: $color-black !important;

    i {
      color: $color-grey !important;
    }

    .btn-link {
      color: $color-black !important;
    }
  }

  input,
  select,
  textarea,
  .fake-input,
  .form-control {
    background: $lightest-grey-overlay !important;
    color: $color-darkgrey !important;

    &.is-invalid,
    &.error {
      border: 1px solid $color-danger !important;
    }
  }

  .radio-checkbox {
    &.show {
      background-color: $partner-brand-color !important;
      border-color: $partner-brand-color !important;

      .dot {
        background: $partner-brand-color;
      }
    }
  }

  .entity-manager {
    .card {
      border: 1px solid $lighter-grey-overlay;

      .item-row {
        background: $lightest-grey-overlay !important;

        &.active {
          background: $lightest-grey-overlay !important;
        }
      }
    }

    &-footer {
      color: $color-black;
    }
  }

  .content-container {
    .header-container {
      border-bottom: 1px solid $lighter-grey-overlay;

      .subheader {
        color: $partner-brand-color !important;
      }
    }
  }

  .react-select-container {
    width: 100%;

    .react-select__control,
    .react-select__control:hover,
    .react-select__control:focus,
    .react-select__control--menu-is-open,
    .react-select__control--is-focused,
    .react-select__indicator-separator {
      background: $lightest-grey-overlay !important;
      border-color: transparent !important;

      .react-select__single-value {
        color: $color-grey;
      }

      .react-select__indicator-separator {
        background: $lighter-grey-overlay !important;
      }
    }

    .react-select__option,
    .react-select__option:hover,
    .react-select__option:focus,
    .react-select__option--is-focused {
      background: $color-white !important;
      border-bottom: 1px solid $lighter-grey-overlay !important;
      color: $color-grey !important;

      &:last-child {
        border-bottom: 0 !important;
      }
    }

    .react-select__menu {
      background: $color-white !important;
    }
  }

  .drop-zone {
    background: radial-gradient($lighter-grey-overlay 1px, transparent 0) !important;
    background-size: 3px 3px !important;
    color: $color-grey !important;
  }

  .modal-content {
    background: $color-white !important;
    color: $color-grey !important;

    .modal-header {
      background: $gradient-white-lightgrey !important;
      color: $black-overlay !important;

      .btn-close {
        &::before {
          color: $black-overlay !important;
        }
      }
    }
  }

  .structure-reloader {
    button {
      color: $color-darkgrey !important;
    }
  }

  .json-editor-holder {
    background: $lightest-grey-overlay;
    box-shadow: inset 0 0 5px $lighter-grey-overlay !important;
  }

  #login-form {
    .card {
      background: $color-white !important;
      border: 1px solid $lighter-grey-overlay !important;
      color: $color-lightgrey !important;

      .instructions {
        color: $color-darkgrey;
      }
    }

    &.sign-up {
      .sign-up-content,
      .sign-up-header {
        color: $color-darkgrey !important;
      }

      .sign-up-header {
        border-bottom: 1px solid $light-grey-overlay;
      }

      hr {
        background: $light-grey-overlay;
      }
    }

    input {
      background: $lightest-grey-overlay !important;
      color: $color-black !important;

      &::placeholder {
        color: $color-grey !important;
      }
    }

    .disclaimer {
      color: $dark-grey-overlay !important;

      a {
        color: $dark-grey-overlay !important;
      }
    }

    .login-nav-link {
      color: $grey-overlay !important;

      &:hover {
        color: $grey-overlay !important;
      }

      &.error {
        color: $color-danger !important;
      }
    }

    .subdomain-label {
      color: $dark-grey-overlay;

      .btn-link {
        color: $light-grey-overlay;
      }
    }
  }

  #charts {
    .instructions {
      color: $color-darkgrey;
    }

    .dashboard-divider {
      background: $lighter-grey-overlay;
    }
  }

  .version {
    color: $color-darkgrey !important;
  }

  .btn-purple {
    background-color: $partner-brand-color !important;
  }

  .text-purple {
    color: $partner-brand-color !important;
  }

  ::placeholder {
    color: $light-grey-overlay !important;
  }
}
