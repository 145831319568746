body {
  background: $color-purple;
  min-width: $min-width;
  overflow-x: hidden;
  position: relative;

  #app {
    min-width: $min-width;

    #app-container {
      height: calc(100vh - 135px);
      margin-top: 135px;
      min-width: $min-width;
      padding: 0 2rem;

      @media screen and (max-width: 991px) {
        padding: 0 1rem;
      }
    }
  }
}
